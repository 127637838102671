import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#C4920D', // GOLD
    },
    primary2: {
      main: '#1E395B', // DARK BLUE
    },
    secondary: {
      main: '#c7cedb', // GREY
    },
    error: {
      main: red.A400, // ERROR RED
    },
    background: {
      default: '#c7cedb', // Set the primary background color to grey
    },
  },
});

export default theme;
