import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Box, TextField } from '@mui/material';
import FaxNumber from 'material-ui-phone-number';
import ValidateEmail from 'email-validator';
import { parsePhoneNumberFromString } from 'libphonenumber-js';


// Functional component for receiver information fields
const ReceiverFields = ({ formData, setFormData }) => {
  const [touchedFields, setTouchedFields] = useState({});

  //Check if a given name is valid.
  const isNameValid = (name) => /^[A-Za-z' --.]*$/.test(name);
  const isCompanyValid = (name) => /^[A-Za-z0-9' --.]*$/.test(name);

  // Handler function to update form data when a field value changes
  const handleChange = (field, value) => {

    // Update the form data using the previous state
    setFormData((prevData) => ({
      ...prevData,
      receiver: {
        ...prevData.receiver,
        [field]: value,
      },
    }));

    // Remove the error outline when typing
    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [field]: false,
    }));
  };

  // Check if the input is valid before marking the field as touched
  const handleBlur = (field) => {
    const isValid =
      field === 'receiverEmail'
        ? ValidateEmail.validate(formData.receiver.receiverEmail)
        : field === 'receiverFaxNumber'
          ? isPhoneNumberValid(formData.receiver.receiverFaxNumber)
          : field === 'receiverName'
            ? isNameValid(formData.receiver.receiverName)
            : field === 'receiverCompany'
              ? isCompanyValid(formData.receiver.receiverCompany)
              : true;

    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [field]: !isValid,
    }));
  };

  // Validate phone number
  const isPhoneNumberValid = (phoneNumber) => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber, 'CA');
    return phoneNumberObj ? phoneNumberObj.isValid() : false;
  };

  // Render the receiver information fields
  return (
    <Container
      maxWidth="sm"
      sx={{
        borderRadius: '15px',
        backgroundColor: 'white',
        padding: '5px',
        marginLeft: '5px',
        marginRight: '5px',
        marginTop: '5px',
        marginBottom: '5px',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': {
            m: 1,
            width: '25ch',
            backgroundColor: 'white',
            borderRadius: '4px',
          },
          '& .MuiInputBase-input': { color: 'black' },
          '& .MuiInputLabel-root': { color: 'black' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            id="outlined-required"
            label="Name"
            value={formData.receiver.receiverName}
            onChange={(e) => handleChange('receiverName', e.target.value)}
            onBlur={() => handleBlur('receiverName')}
            error={touchedFields.receiverName && !isNameValid(formData.receiver.receiverName)}
            helperText={
              (touchedFields.receiverName && formData.receiver.receiverName.trim() === '')
                ? ''
                : (touchedFields.receiverName && !isNameValid(formData.receiver.receiverName))
                  ? 'Please enter a valid name.'
                  : ''
            }
          />
          <TextField
            id="outlined-required"
            label="Company"
            value={formData.receiver.receiverCompany}
            onChange={(e) => handleChange('receiverCompany', e.target.value)}
            onBlur={() => handleBlur('receiverCompany')}
            error={touchedFields.receiverCompany && !isCompanyValid(formData.receiver.receiverCompany)}
            helperText={
              (touchedFields.receiverCompany && formData.receiver.receiverCompany.trim() === '')
                ? ''
                : (touchedFields.receiverCompany && !isCompanyValid(formData.receiver.receiverCompany))
                  ? 'Please enter a valid company name.'
                  : ''
            }
          />
          <TextField
            required
            id="outlined-required"
            label="Email"
            value={formData.receiver.receiverEmail}
            onChange={(e) => handleChange('receiverEmail', e.target.value)}
            onBlur={() => handleBlur('receiverEmail')}
            error={touchedFields.receiverEmail && !ValidateEmail.validate(formData.receiver.receiverEmail)}
            helperText={
              touchedFields.receiverEmail &&
                !ValidateEmail.validate(formData.receiver.receiverEmail)
                ? 'Please enter a valid email address.'
                : ''
            }
          />
          <FaxNumber
            required
            defaultCountry={'ca'} // Set the default country (change as needed)
            label="Fax Number"
            value={formData.receiver.receiverFaxNumber}
            onChange={(value) => handleChange('receiverFaxNumber', value)}
            onBlur={() => handleBlur('receiverFaxNumber')}
            error={
              touchedFields.receiverFaxNumber &&
              !isPhoneNumberValid(formData.receiver.receiverFaxNumber)
            }
            helperText={
              touchedFields.receiverFaxNumber &&
                !isPhoneNumberValid(formData.receiver.receiverFaxNumber)
                ? 'Please enter a valid fax number.'
                : ''
            }
            variant="outlined"

          />
        </div>
      </Box>
    </Container>
  );
};

// Define prop types for the component
ReceiverFields.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
};

// Export the component as the default export
export default ReceiverFields;
