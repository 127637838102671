// Portal hosted online. use FileZilla to load build. When making changes "npm run build". Put build into hosted through filezilla.
import React, { useState, useRef } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  Link,
  TextField,
  IconButton,
  AppBar,
  Toolbar,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { Editor } from "react-draft-wysiwyg";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CancelIcon from '@mui/icons-material/Cancel';
import ReceiverFields from './ReceiverFields';
import SenderFields from './SenderFields';
import FaxSenderLogo from './logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import ValidateEmail from 'email-validator';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

// Styling utility for visually hiding input
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

// Copyright component
function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.altinc.ca/">
        Alt Telecom
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// Main functional component App
export default function App() {
  const theme = useTheme();

  // State variables for managing form data, editor state, file uploads, status, and captcha
  const [captchaValue, setCaptchaValue] = useState('');
  const [showCaptchaPopup, setShowCaptchaPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [captchaErrorMessage, setCaptchaErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    sender: {
      senderName: '',
      senderCompany: '',
      senderEmail: '',
      senderPhoneNumber: ''
    },
    receiver: {
      receiverName: '',
      receiverCompany: '',
      receiverEmail: '',
      receiverFaxNumber: ''
    }
  });
  const [editorState, setEditorState] = useState(null);
  const [fileUploadButtons, setFileUploadButtons] = useState(1);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [status, setStatus] = useState('default');
  const EditorRef = useRef();

  // Function to handle changes in the WYSIWYG editor
  // const onEditorStateChange = (newEditorState) => {
  //   setEditorState(newEditorState);
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     editor: newEditorState.getCurrentContent().getPlainText(),
  //   }));
  // };

  const onEditorStateChange = (newEditorContent) => {
    setFormData((prevData) => ({
      ...prevData,
      editor: newEditorContent,
    }));
  };
  // Function to handle file uploads
  const handleFileUpload = (event, index) => {
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const newUploadedFiles = [...uploadedFiles];
      newUploadedFiles[index] = { name: file.name, file };
      setUploadedFiles(newUploadedFiles);

      if (fileUploadButtons < 3) {
        setFileUploadButtons((prevButtons) => prevButtons + 1);
      }

      fileInput.value = '';
    }
  };

  // Function to validate phone numbers using libphonenumber-js
  const isNumberValid = (Number) => {
    try {
      const parsedNumber = parsePhoneNumberFromString(Number);
      return parsedNumber ? parsedNumber.isValid() : false;
    } catch (error) {
      console.error('Error validating number:', error);
      return false;
    }
  };

  // Function to format phone numbers using libphonenumber-js
  const formatPhoneNumber = (phoneNumber) => {
    const numericNumber = parsePhoneNumberFromString(phoneNumber);
    if (numericNumber) {
      return `${numericNumber.countryCallingCode}${numericNumber.nationalNumber}`;
    }
    return phoneNumber; // return original if not valid
  };

  // Function to handle file deletions
  const handleFileDelete = (index) => {
    const newUploadedFiles = [...uploadedFiles];
    newUploadedFiles[index] = null;
    setUploadedFiles(newUploadedFiles);

    setFileUploadButtons((prevButtons) => prevButtons - 1);
  };

  //Check if a given name is valid.
  const isNameValid = (name) => /^[A-Za-z' --.]+$/.test(name);
  const isCompanyValid = (name) => /^[A-Za-z0-9' --.]*$/.test(name);

  // Function to render file upload buttons based on the current state
  const renderFileUploadButtons = () => {
    const buttons = [];
    const maxButtons = 3;
    if (uploadedFiles.length === 0) {
      for (let i = 0; i < maxButtons; i++) {
        buttons.push(
          <Box key={i} sx={{ my: 0.5 }}>
            <Button
              color="secondary"
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <VisuallyHiddenInput
                type="file"
                accept="application/pdf"
                onChange={(event) => handleFileUpload(event, i)}
              />
            </Button>
          </Box>
        );
      }
    } else {
      for (let i = 0; i < maxButtons; i++) {
        buttons.push(
          <Box key={i} sx={{ my: 0.5 }}>
            <Button
              color="secondary"
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <VisuallyHiddenInput
                type="file"
                onChange={(event) => handleFileUpload(event, i)}
              />
            </Button>
            {uploadedFiles[i] && (
              <Box
                key={i}
                sx={{
                  my: 1,
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#f0f0f0',
                  borderRadius: '4px',
                  justifyContent: 'space-between',
                  padding: '8px',
                }}
              >
                <TextField
                  label="Uploaded File"
                  variant="outlined"
                  fullWidth
                  disabled
                  value={uploadedFiles[i].name}
                />
                <IconButton onClick={() => handleFileDelete(i)}>
                  <CancelIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        );
      }
    }
    return buttons;
  };

  // Function to handle form submission.
  const handleSubmit = async () => {
    try {
      const isSenderNameValid = formData.sender.senderName ? isNameValid(formData.sender.senderName) : true;
      const isSenderCompanyValid = formData.sender.senderCompany ? isCompanyValid(formData.sender.senderCompany) : true;
      const isReceiverNameValid = formData.receiver.receiverName ? isNameValid(formData.receiver.receiverName) : true;
      const isReceiverCompanyValid = formData.receiver.receiverCompany ? isCompanyValid(formData.receiver.receiverCompany) : true;

      const isSenderValid = validateFields(formData.sender, ['senderEmail', 'senderPhoneNumber']);
      const isReceiverValid = validateFields(formData.receiver, ['receiverEmail', 'receiverFaxNumber']);

      if (!isSenderValid || !isReceiverValid) {
        setErrorMessage('Please fill in all required fields.');
        return;
      }

      if (!isSenderNameValid || !isSenderCompanyValid || !isReceiverNameValid || !isReceiverCompanyValid) {
        setErrorMessage('Please enter valid names and company names or leave them blank.');
        return;
      }


      if (!ValidateEmail.validate(formData.sender.senderEmail) || !ValidateEmail.validate(formData.receiver.receiverEmail)) {
        setErrorMessage('Please enter a valid email address.');
        return false;
      }

      const isSenderPhoneValid = isNumberValid(formData.sender.senderPhoneNumber);
      const isReceiverFaxValid = isNumberValid(formData.receiver.receiverFaxNumber);

      if (!isSenderPhoneValid && !isReceiverFaxValid) {
        setErrorMessage('Please enter valid numbers.');
        return;
      } else if (!isSenderPhoneValid && isReceiverFaxValid) {
        setErrorMessage('Please enter a valid phone number.');
        return;
      } else if (isSenderPhoneValid && !isReceiverFaxValid) {
        setErrorMessage('Please enter a valid fax number.');
        return;
      }

      // Format the phone numbers before sending
      const formattedSenderPhoneNumber = formatPhoneNumber(formData.sender.senderPhoneNumber);
      const formattedReceiverFaxNumber = formatPhoneNumber(formData.receiver.receiverFaxNumber);

      // Update the form data with formatted values
      setFormData((prevData) => ({
        ...prevData,
        sender: {
          ...prevData.sender,
          senderPhoneNumber: formattedSenderPhoneNumber,
        },
        receiver: {
          ...prevData.receiver,
          receiverFaxNumber: formattedReceiverFaxNumber,
        },
      }));

      setErrorMessage('');
      // Prompt for captcha verification
      setShowCaptchaPopup(true);
    } catch (error) {
      console.error('There was an error with the form.');
    }
  };

  // Function to handle form submission
  const handleFormSubmit = async () => {
    try {
      const uploadedFileNames = uploadedFiles.map((file) => (file ? file.name : null));

      console.log(uploadedFiles);
      console.log('Sender:', formData.sender);
      console.log('Receiver:', formData.receiver);
      console.log('Fax Info:', { files: uploadedFileNames });

      const newFormData = new FormData();
      var jsonData = JSON.stringify(formData);
      var blob = new Blob([jsonData], { type: 'application/json' });

      newFormData.append('data', blob);
      newFormData.append('editor', formData.editor);

      uploadedFiles.forEach((file) => {
        if (file) {
          newFormData.append('file', file.file);
        }
      });

      if (editorState) {
        console.log('Editor Text:', editorState.getCurrentContent().getPlainText());
      }

      const response = await axios.post('https://darla.hfx.apbx.ca:8443/sendfax', newFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setStatus('success');
        console.log('Request processed response:', response);
        console.log('Response.status:' + response.status);
        console.log('Status:' + status);
      }
    } catch (error) {
      if (error.response) {
        setStatus('error');
        console.error('Server responded with an error:', error.response.status);
        console.log('Response data:', error.response.data);
        console.log('Response headers:', error.response.headers);
      } else if (error.request) {
        setStatus('error2');
        console.error('Unexpected status code:', error.response.status);
        console.log('Response.status:' + error.response.data);
        console.log('Response headers:', error.response.headers);
      } else {
        setStatus('error3');
        console.error('Error handling request:', error.response.status);
        console.log('Response.status:' + error.response.data);
        console.log('Response headers:', error.response.headers);
      }
    }

    setEditorState(null);
    setUploadedFiles([]);
    setFileUploadButtons(1);
    setFormData({
      sender: { senderName: '', senderCompany: '', senderEmail: '', senderPhoneNumber: '' },
      receiver: { receiverName: '', receiverCompany: '', receiverEmail: '', receiverFaxNumber: '' },
      faxInfo: {
        files: []
      },
    });
  };

  // Function to validate form fields
  const validateFields = (data, requiredFields) => {
    for (const field of requiredFields) {
      if (!data[field]) {
        return false;
      }
    }
    return true;
  };

  // Function to handle return to default state
  const handleReturn = () => {
    setStatus('default');
    setEditorState(null);
    setUploadedFiles([]);
    setFileUploadButtons(1);
    setFormData({
      sender: { senderName: '', senderCompany: '', senderEmail: '', senderPhoneNumber: '' },
      receiver: { receiverName: '', receiverCompany: '', receiverEmail: '', receiverFaxNumber: '' },
      faxInfo: {
        files: []
      },
    });
    setCaptchaValue('');
  };

  // Function to handle captcha form submission
  const handleCaptchaSubmit = () => {
    if (!captchaValue) {
      setCaptchaErrorMessage('Please complete the reCAPTCHA verification.');
      return;
    }
    setShowCaptchaPopup(false);
    handleFormSubmit();
  };

  //Function that renders a popup for reCAPTCHA verification.
  const renderCaptchaPopup = () => {
    return (
      <script src="https://www.google.com/recaptcha/api.js" async defer>
        <Dialog open={showCaptchaPopup} onClose={() => setShowCaptchaPopup(false)}>
          <DialogTitle>Captcha Verification</DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1" gutterBottom>
              Please complete the reCAPTCHA below.
            </Typography>
            {captchaErrorMessage && (
              <Typography variant="body2" color="error" align="center" paragraph>
                {captchaErrorMessage}
              </Typography>
            )}
            <ReCAPTCHA
              sitekey="6LeT-Y4pAAAAAJxgx4khacKO0tpa95RcxaeTsX9u" // Replace with your actual reCAPTCHA site key
              onChange={(value) => {
                setCaptchaValue(value);
                setCaptchaErrorMessage('');
              }}
            />
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button onClick={() => setShowCaptchaPopup(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCaptchaSubmit} color="primary" variant="contained">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </script>
    );
  };

  // Function to render different content based on the current status
  const renderContent = () => {
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const iconSize = isSmallScreen ? '4x' : '7x';
    const verticalLineHeight = isSmallScreen ? '100px' : '180px';
    const titleFontSize = isSmallScreen ? '22px' : '28px';
    const textFontSize = isSmallScreen ? '14px' : '16px';
    const returnBoxPadding = isSmallScreen ? '5px' : '10px';

    switch (status) {
      case 'success':
        // Success Transition
        return (
          <Container
            maxWidth="sm"
            sx={{
              borderRadius: '15px',
              backgroundColor: '#1e395b',
              padding: '20px',
              marginTop: '20px',
              marginBottom: '20px',
              minHeight: '50vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              boxShadow: '5px 5px 4px rgba(0, 0, 0, .5)',
              position: 'relative',
            }}
          >
            <Box
              paddingLeft='30px'
              paddingBottom='0px'
              sx={{
                padding: '15px',
                position: 'relative',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                backgroundColor: 'white',
                borderRadius: '15px',
              }}
            >
              <FontAwesomeIcon icon={faCheckCircle} className={`fa-${iconSize}`} style={{ color: '#5ae271' }} />
              <Typography
                sx={{
                  height: verticalLineHeight,
                  margin: '20px 20px',
                  borderRadius: '5px',
                  borderLeft: '4px solid #000',
                }}
              />
              <Box sx={{ marginLeft: '20px' }}>
                <Typography variant="subtitle1" component="subtitle1" sx={{ color: 'black', fontSize: titleFontSize, fontWeight: 'bold' }}>
                  Success!
                </Typography>
                <Typography sx={{ color: 'black', fontSize: textFontSize, fontWeight: 'normal' }}>
                  Your fax has been successfully transmitted.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ padding: returnBoxPadding }}>
              <Button color="secondary" onClick={handleReturn} variant="contained">
                Return
              </Button>
            </Box>
          </Container>
        );
      case 'error':
        // Failed Fax Error Transition
        return (
          <Container
            maxWidth="sm"
            sx={{
              borderRadius: '15px',
              backgroundColor: '#1e395b',
              padding: '20px',
              marginTop: '20px',
              marginBottom: '20px',
              minHeight: '50vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              boxShadow: '5px 5px 4px rgba(0, 0, 0, .5)',
              position: 'relative',
            }}
          >
            <Box
              paddingLeft='30px'
              paddingBottom='0px'
              sx={{
                padding: '15px',
                position: 'relative',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                backgroundColor: 'white',
                borderRadius: '15px',
              }}
            >
              <FontAwesomeIcon icon={faCircleXmark} className={`fa-${iconSize}`} style={{ color: "#ff0033", }} />
              <Typography
                sx={{
                  height: verticalLineHeight,
                  margin: '20px 20px',
                  borderRadius: '5px',
                  borderLeft: '4px solid #000',
                }}
              />
              <Box sx={{ marginLeft: '20px' }}>
                <Typography variant="subtitle1" component="subtitle1" sx={{ color: 'black', fontSize: titleFontSize, fontWeight: 'bold' }}>
                  Fax Transmission Failed
                </Typography>
                <Typography sx={{ color: 'black', fontSize: textFontSize, fontWeight: 'normal' }}>
                  Please Try Again.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ padding: returnBoxPadding }}>
              <Button color="secondary" onClick={handleReturn} variant="contained">
                Return
              </Button>
            </Box>
          </Container>
        );
      case 'error2':
        // Unexpected Status Code Error Transition
        return (
          <Container
            maxWidth="sm"
            sx={{
              borderRadius: '15px',
              backgroundColor: '#1e395b',
              padding: '20px',
              marginTop: '20px',
              marginBottom: '20px',
              minHeight: '50vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              boxShadow: '5px 5px 4px rgba(0, 0, 0, .5)',
              position: 'relative',
            }}
          >
            <Box
              paddingLeft='30px'
              paddingBottom='0px'
              sx={{
                padding: '15px',
                position: 'relative',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                backgroundColor: 'white',
                borderRadius: '15px',
              }}
            >
              <FontAwesomeIcon icon={faCircleXmark} className={`fa-${iconSize}`} style={{ color: "#ff0033", }} />
              <Typography
                sx={{
                  height: verticalLineHeight,
                  margin: '20px 20px',
                  borderRadius: '5px',
                  borderLeft: '4px solid #000',
                }}
              />
              <Box sx={{ marginLeft: '20px' }}>
                <Typography variant="subtitle1" component="subtitle1" sx={{ color: 'black', fontSize: titleFontSize, fontWeight: 'bold' }}>
                  Unexpected Status Code
                </Typography>
                <Typography sx={{ color: 'black', fontSize: textFontSize, fontWeight: 'normal' }}>
                  Please Try Again.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ padding: returnBoxPadding }}>
              <Button color="secondary" onClick={handleReturn} variant="contained">
                Return
              </Button>
            </Box>
          </Container>
        );
      case 'error3':
        // Handling Request Error Transition
        return (
          <Container
            maxWidth="sm"
            sx={{
              borderRadius: '15px',
              backgroundColor: '#1e395b',
              padding: '20px',
              marginTop: '20px',
              marginBottom: '20px',
              minHeight: '50vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              boxShadow: '5px 5px 4px rgba(0, 0, 0, .5)',
              position: 'relative',
            }}
          >
            <Box
              paddingLeft='30px'
              paddingBottom='0px'
              sx={{
                padding: '15px',
                position: 'relative',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                backgroundColor: 'white',
                borderRadius: '15px',
              }}
            >
              <FontAwesomeIcon icon={faCircleXmark} className={`fa-${iconSize}`} style={{ color: "#ff0033", }} />
              <Typography
                sx={{
                  height: verticalLineHeight,
                  margin: '20px 20px',
                  borderRadius: '5px',
                  borderLeft: '4px solid #000',
                }}
              />
              <Box sx={{ marginLeft: '20px' }}>
                <Typography variant="subtitle1" component="subtitle1" sx={{ color: 'black', fontSize: titleFontSize, fontWeight: 'bold' }}>
                  Error Handling Request
                </Typography>
                <Typography sx={{ color: 'black', fontSize: textFontSize, fontWeight: 'normal' }}>
                  Please Try Again.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ padding: returnBoxPadding }}>
              <Button color="secondary" onClick={handleReturn} variant="contained">
                Return
              </Button>
            </Box>
          </Container>
        );
      default:
        // Form Page
        return (
          <Container
            maxWidth="sm"
            sx={{
              borderRadius: '15px',
              backgroundColor: '#1e395b',
              padding: '20px',
              marginTop: '20px', // Adjust margin top as needed
              marginBottom: '20px', // Add bottom margin
              minHeight: '100vh', // Fill the entire viewport height
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', // Center content horizontally
              justifyContent: 'space-between', // Distribute space between items
              boxShadow: '5px 5px 4px rgba(0, 0, 0, .5)',
            }}
          >
            <Box sx={{ my: 2 }} >
              <Typography variant="h4" component="h1" color="white" gutterBottom>
                Sender
              </Typography>
              <SenderFields formData={formData} setFormData={setFormData} />
            </Box>
            <Box sx={{ my: 2 }}>
              <Typography variant="h4" component="h1" color="white" gutterBottom>
                Receiver
              </Typography>
              <ReceiverFields formData={formData} setFormData={setFormData} />
            </Box>
            <Box sx={{ my: 2 }}>
              <Typography variant="h4" component="h1" color="white" gutterBottom>
                Fax Info
              </Typography>
              <Box sx={{ my: .5 }}>
                <Typography variant="subtitle1" component="subtitle1" color="white" gutterBottom>
                  Attach one or more PDF files.
                </Typography>
              </Box>
              {renderFileUploadButtons()}
              <Box sx={{ my: 2 }}>
                <Typography variant="subtitle1" component="subtitle1" color="white" gutterBottom>
                  Type text that you want to appear on the cover page.
                </Typography>
              </Box>
              <Box
                maxWidth="sm"
                sx={{
                  borderRadius: '15px',
                  backgroundColor: 'white',
                  padding: '12px',
                  marginLeft: '5px',
                  marginRight: '5px',
                  marginTop: '5px',
                  marginBottom: '5px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                <Box
                  sx={{
                    borderRadius: '5px',
                    backgroundColor: 'white',
                  }}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Add Note"
                    multiline
                    rows={4}
                    sx={{
                      borderRadius: '5px',
                      backgroundColor: 'white',
                      width: '515px'
                    }}
                    value={formData.editor || ''}  // Ensure the form data state includes the editor content
                    onChange={(e) => onEditorStateChange(e.target.value)}
                  />
                  {/* <Editor
                  sx={{
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    paddingTop: '5px',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    marginBottom: '5px',
                    backgroundColor: 'white',
                    height: '300px'
                  }}
                  ref={(ref) => (EditorRef.current = ref)}
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={onEditorStateChange}
                  editorStyle={{ height: '200px' }}
                /> */}
                </Box>
              </Box>
            </Box>
            {
              errorMessage && (
                <Box sx={{ my: 2, color: theme.palette.error.main, textAlign: 'center' }}>
                  {errorMessage}
                </Box>
              )
            }
            <Box sx={{ my: 2 }}>
              <Button
                color="secondary"
                onClick={handleSubmit}
                variant="contained"
              >
                Submit
              </Button>
            </Box>
            <Copyright></Copyright>
          </Container >
        );
    }
  };


  return (
    // App Bar and renderContent
    <div style={{ backgroundColor: '#c7cedb', minHeight: '100vh' }}>
      <AppBar color="primary2" position="static">
        <Toolbar>
          <Typography color="white" variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Fax Sender
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            href="https://altinc.ca/"
            target="_blank"
          >
            <img
              src={FaxSenderLogo}
              alt="Fax Sender Logo"
              style={{ width: 40, height: 40 }}
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      {renderContent()}
      {renderCaptchaPopup()}
    </div>
  );
}
