import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Box, TextField } from '@mui/material';
import PhoneNumber from 'material-ui-phone-number';
import ValidateEmail from 'email-validator';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

// Functional component for sender information fields
const SenderFields = ({ formData, setFormData }) => {
  const [touchedFields, setTouchedFields] = useState({});

  // Check if a given name is valid.
  const isNameValid = (name) => /^[A-Za-z' --.]*$/.test(name);
  const isCompanyValid = (name) => /^[A-Za-z0-9' --.]*$/.test(name);

  // Handler function to update form data when a field value changes
  const handleChange = (field, value) => {
    // Update the form data using the previous state
    setFormData((prevData) => ({
      ...prevData,
      sender: {
        ...prevData.sender,
        [field]: value,
      },
    }));

    // Remove the error outline when typing
    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [field]: false,
    }));
  };
  // Handler function to mark a field as touched
  const handleBlur = (field) => {
    // Check if the input is valid before marking the field as touched
    const isValid =
      field === 'senderEmail'
        ? ValidateEmail.validate(formData.sender.senderEmail)
        : field === 'senderPhoneNumber'
          ? isPhoneNumberValid(formData.sender.senderPhoneNumber)
          : field === 'senderName'
            ? isNameValid(formData.sender.senderName)
            : field === 'senderCompany'
              ? isCompanyValid(formData.sender.senderCompany)
              : true;

    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [field]: !isValid,
    }));
  };

  // Validate phone number
  const isPhoneNumberValid = (phoneNumber) => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber, 'CA');
    return phoneNumberObj ? phoneNumberObj.isValid() : false;
  };

  // Render the sender information fields
  return (
    <Container
      maxWidth="sm"
      sx={{
        borderRadius: '15px',
        backgroundColor: 'white',
        padding: '5px',
        marginLeft: '5px',
        marginRight: '5px',
        marginTop: '5px',
        marginBottom: '5px',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': {
            m: 1,
            width: '25ch',
            backgroundColor: 'white',
            borderRadius: '4px',
          },
          '& .MuiInputBase-input': { color: 'black' },
          '& .MuiInputLabel-root': { color: 'black' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            id="outlined-required"
            label="Name"
            value={formData.sender.senderName}
            onChange={(e) => handleChange('senderName', e.target.value)}
            onBlur={() => handleBlur('senderName')}
            error={touchedFields.senderName && !isNameValid(formData.sender.senderName)}
            helperText={
              (touchedFields.senderName && formData.sender.senderName.trim() === '')
                ? ''  // Clear the error when the field is touched and empty
                : (touchedFields.senderName && !isNameValid(formData.sender.senderName))
                  ? 'Please enter a valid name.'
                  : ''
            }
          />
          <TextField
            id="outlined-required"
            label="Company"
            value={formData.sender.senderCompany}
            onChange={(e) => handleChange('senderCompany', e.target.value)}
            onBlur={() => handleBlur('senderCompany')}
            error={touchedFields.senderCompany && !isCompanyValid(formData.sender.senderCompany)}
            helperText={
              (touchedFields.senderCompany && formData.sender.senderCompany.trim() === '')
                ? ''
                : (touchedFields.senderCompany && !isCompanyValid(formData.sender.senderCompany))
                  ? 'Please enter a valid company name.'
                  : ''
            }
          />
          <TextField
            required
            id="outlined-required"
            label="Email"
            value={formData.sender.senderEmail}
            onChange={(e) => handleChange('senderEmail', e.target.value)}
            onBlur={() => handleBlur('senderEmail')}
            error={touchedFields.senderEmail && !ValidateEmail.validate(formData.sender.senderEmail)}
            helperText={
              touchedFields.senderEmail &&
                !ValidateEmail.validate(formData.sender.senderEmail)
                ? 'Please enter a valid email address.'
                : ''
            }
          />
          <PhoneNumber
            required
            defaultCountry={'ca'} // Set the default country (change as needed)
            label="Phone Number"
            value={formData.sender.senderPhoneNumber}
            onChange={(value) => handleChange('senderPhoneNumber', value)}
            onBlur={() => handleBlur('senderPhoneNumber')}
            error={
              touchedFields.senderPhoneNumber &&
              !isPhoneNumberValid(formData.sender.senderPhoneNumber)
            }
            helperText={
              touchedFields.senderPhoneNumber &&
                !isPhoneNumberValid(formData.sender.senderPhoneNumber)
                ? 'Please enter a valid phone number.'
                : ''
            }
            variant="outlined"
          />
        </div>
      </Box>
    </Container>
  );
};

// Define prop types for the component
SenderFields.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
};

// Export the component as the default export
export default SenderFields;
